.skills__list {
  max-width: 450px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  i {
    margin-right: 5px;
  }
}

.skills__list-item {
  margin: 0.5em;
}

@media (max-width: 600px) {
  .skills__list-item {
    span {
      margin-left: 10px;
    }
  }
}

#magicui_section {
  background: url('/public/img/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 50px;
  border-radius: 10px;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-filter: grayscale(100%);

  &:hover {
    -webkit-filter: none;
  }

  div {
    border-radius: 10px;
  }
}