.header {
  height: 8em;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}

.avatar_title{
  display: flex;
  text-align: center;
  align-items: center;

  h3 {
    margin-left: 10px;
  }
}


@media (max-width: 600px) {
  .header {
    height: 6em;
  }

  .link{
    margin-left: 10px;
  }
}
